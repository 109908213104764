/* eslint-disable */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'ie-array-find-polyfill';
import 'url-search-params-polyfill';
// IE 11 fixes
require('es6-promise').polyfill();

import '../config/wdyr';
import '../config/a11y';
// IE 11 fixes, this should be on the top!

import React from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.less';
import setupStore from './store';
import { TOAST_OPTIONS } from '@app/constants/toast-options';

import { ChakraProvider } from '@chakra-ui/react'
import theme from './chakra-theme';

import Root from './components/Root';
// eslint-disable-line

const store = setupStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ChakraProvider theme={theme} toastOptions={{ defaultOptions: TOAST_OPTIONS }}>
          <Root />
        </ChakraProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </CookiesProvider>,
);
