/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-filename-extension */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import PropTypes, { string } from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { TextAreaInput } from '@himarley/unity';
import { clearFileUploadStatus, cancelUpload } from '@app/actions/media';
import {
  encodeEmoji,
  getEmojis,
  formatMessageWithEmojis,
} from '@app/helpers/emoji';
import { newTyping, cancelScheduledMessage } from '@app/actions/chat';
import {
  sendMessage,
  setCaseLanguage,
  setActiveChat,
  showSendFileModal,
} from '@app/actions/job';
import { templatesSlice } from '@app/slices/templates';

import { languagesById } from '@app/helpers/languages';
import { TRANSLATE_ACCESS } from '@app/constants/permissions';
import SendIcon from '@app/../images/icons/send.svg';
import TranslateIcon from '@app/../images/icons/translate.svg';
import { ENTER_CODE, ESC_CODE } from '@app/constants/keycodes';
import Emojis from './Emojis/Emojis';
import Typing from './Typing/Typing';
import Button from '../../../elements/buttons/Button/Button';
import FileUploadIndicator from './FileUploadIndicator/FileUploadIndicator';
import DropdownWithMenu from '../../../elements/dropdowns/DropdownWithMenu/DropdownWithMenu';
import selector from './selector';
import ChatInputButtons from './ChatInputButtons';
import './ChatInput.less';
import useDynamicValue from '../../../elements/hooks/useDynamicValue';
import { usePrevious } from '../../../Hooks/usePrevious';
import useKeyNav from '../../../elements/hooks/useKeyNav';
import useKeyTrigger from '../../../elements/hooks/useKeyTrigger';
import { setTemplateTrayIsOpen } from '@app/actions/ui';

const emojis = getEmojis();
const EMOJI = 'EMOJI';
const TEMPLATE = 'TEMPLATE';
export const CHARACTER_INPUT_LIMIT = 1400;
export const MIN_HEIGHT = 38;
export const LINE_HEIGHT = 0;

const ChatInput = ({
  loadedTemplateError,
  chatId,
  newTyping,
  activeChatRecipient,
  sendMessage,
  jobId,
  previewFile,
  uploadFileUrl,
  verifiedUser,
  participants,
  uploadFileIsInProgress,
  uploadFileError,
  handleFileSelectChange,
  disabled,
  scheduledMessages,
  queueMessage,
  setCaseLanguage,
  inTranslationMode,
  languagePreference,
  typingUser,
  cancelScheduledMessage,
  setTemplateTrayIsOpen,
}) => {
  const { clearLoadedTemplate } = templatesSlice.actions;
  const textInput = useRef(null);
  const [value, setValue] = useState('');
  const [height, setHeight] = useState(MIN_HEIGHT);
  const [activePopup, setActivePopup] = useState(null);
  const [additionalTriggerActions, setAdditionalTriggerActions] = useState([]);
  const dispatch = useDispatch();
  const {
    templateModalContext, loadedTemplateText, templateTrayIsOpen,
  } = useSelector(
    ({
      ui, templates, jobs,
    }) => ({
      templateModalContext: ui.context,
      loadedTemplateText: templates.loadedTemplateText,
      activeJobId: jobs.activeJob._id,
      templateTrayIsOpen: ui.templateTrayIsOpen,
    }),
  );

  const { startRecord, replaceSelection } = useDynamicValue({
    input: textInput,
    options: { noToken: true },
  });

  const { handleKeyNav, currentIndex, resetIndex } = useKeyNav({
    options: { length: emojis.length, dimensions: 2, perRow: 15 },
    disabled: activePopup !== EMOJI,
  });

  const triggerActions = [
    {
      replacementAction: () => {
        handleSubmit();
      },
      trigger: ({ keyCode, isShift }) => keyCode === ENTER_CODE && isShift,
      preventDefault: true,
    },
    {
      replacementAction: () => setActivePopup(),
      trigger: ({ keyCode }) => keyCode === ESC_CODE,
    },
  ];

  const { handleKeyTrigger } = useKeyTrigger({
    triggerActions: [...triggerActions, ...additionalTriggerActions],
  });

  useEffect(() => {
    if (uploadFileUrl) setValue(uploadFileUrl);
  }, [uploadFileUrl]);

  useEffect(() => {
    if (!activePopup) resetIndex();
  }, [activePopup]);

  const prevJobId = usePrevious(jobId);
  const clearOnJobSwitch = () => {
    if (prevJobId !== jobId) {
      setValue('');
    }
    if (textInput?.current?.focus) {
      setTimeout(() => {
        textInput.current?.focus();
      }, 300);
    }
  };
  useEffect(clearOnJobSwitch, [jobId]);

  const injectTemplate = () => {
    if (templateModalContext === 'CHAT_INPUT' && loadedTemplateText && !loadedTemplateError) {
      setValue(loadedTemplateText.substring(0, CHARACTER_INPUT_LIMIT));
      dispatch(clearLoadedTemplate());
    }
  };
  useEffect(injectTemplate, [loadedTemplateText, templateModalContext, loadedTemplateError, clearLoadedTemplate]);

  const setInputBoxHeight = () => {
    const currentInput = textInput?.current;
    if (currentInput) {
      let currentHeight = currentInput.scrollHeight || MIN_HEIGHT;
      if (value.length === 0) currentHeight = MIN_HEIGHT;
      if (currentHeight > 100) currentHeight = 100;
      if (currentHeight !== height) {
        setHeight(currentHeight);
      }
    }
  };
  useEffect(setInputBoxHeight, [value]);

  const handleChange = (event) => {
    const v = event?.target?.value || '';
    setValue(v);
    newTyping({ chatId });
  };

  const handleKeyDown = (e) => {
    handleKeyNav(e);
    handleKeyTrigger(e);
  };

  const handleSubmit = () => {
    const message = formatMessageWithEmojis(
      value.replace(/<br\s*\/?>/gm, '\n').trim(),
    );
    if (message) {
      if (verifiedUser) {
        sendMessage({ chatId, jobId, message });
        setValue('');
      }
    }
  };

  const selectEmoji = (i) => {
    const newValue = replaceSelection(encodeEmoji(emojis[i]));
    setActivePopup();
    setValue(newValue);
    textInput.current.focus();
  };

  useEffect(() => {
    const replacementPopups = [EMOJI];
    if (replacementPopups.includes(activePopup)) {
      startRecord();
    }

    setAdditionalTriggerActions(
      activePopup === EMOJI
        ? [
          {
            replacementAction: () => selectEmoji(currentIndex),
            trigger: ({ keyCode }) => keyCode === ENTER_CODE,
            preventDefault: true,
          },
        ]
        : [],
    );
  }, [activePopup, currentIndex]);

  const clearAndFocusTokenSelection = () => {
    textInput.current.focus();
  };

  const togglePanel = (type) => {
    setActivePopup(type);
    if (type === TEMPLATE) {
      setTemplateTrayIsOpen(!templateTrayIsOpen, 'CHAT_INPUT');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      templateTrayIsOpen && setTemplateTrayIsOpen(false);
    }
    textInput.current.focus();
  };

  const disableComponent = disabled;
  const activeRecipient = activeChatRecipient;

  const renderLanguageSelectDropDown = useMemo(() => {
    const selectLanguage = (languagePreference) => {
      setCaseLanguage({ caseId: jobId, languagePreference });
    };
    return (
      <DropdownWithMenu
        testId="chat-input-translate-button"
        neededPermissions={[TRANSLATE_ACCESS]}
        Icon={TranslateIcon}
        selectLanguage={selectLanguage}
        value={languagesById[languagePreference] || 'en'}
      />
    );
  }, [jobId, languagePreference, setCaseLanguage]);
  return (
    <>
      <Emojis
        className="emoji-panel"
        showEmoji={activePopup === EMOJI}
        setShowEmoji={(o) => setActivePopup(o ? EMOJI : null)}
        emojis={emojis}
        selectEmoji={selectEmoji}
        currentIndex={currentIndex}
        style={{ bottom: height + 100 + (inTranslationMode ? 24 : 0) }}
      />
      <div className="chat-input-component">
        <div className="chat-input-panel">
          {inTranslationMode && (
            <div
              className="chat-input-translate-bar"
              data-testid={`chat-input-translate-bar-${jobId}`}
            >
              Translation On (
              {languagesById[languagePreference] || 'en'}
              )
            </div>
          )}

          {uploadFileIsInProgress && (
            <FileUploadIndicator
              previewFile={previewFile}
              error={uploadFileError}
              onCancel={cancelUpload}
            />
          )}

          {!uploadFileIsInProgress && (
            <TextAreaInput
              data-testid="chat-input-textarea"
              className="chat-input-textarea"
              style={{ maxHeight: 100, height }}
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder={`Write ${activeRecipient.name}`}
              ref={textInput}
              autoFocus
              disabled={disableComponent}
              maxLength={CHARACTER_INPUT_LIMIT}
            />
          )}

          <div className="under-type-bar">
            <ChatInputButtons
              togglePanel={togglePanel}
              clearAndFocusTokenSelection={clearAndFocusTokenSelection}
              setCaseLanguage={setCaseLanguage}
              caseId={jobId}
              handleFileSelectChange={handleFileSelectChange}
              participants={participants}
              queueMessage={queueMessage}
              chatId={chatId}
              jobId={jobId}
              scheduledMessages={scheduledMessages}
              cancelScheduledMessage={cancelScheduledMessage}
              verifiedUser={!disableComponent}
            />
            <Button
              type="positive"
              className="chat-input-message-send-button"
              data-jest="chat-input-send-button"
              onClick={handleSubmit}
              disabled={disableComponent}
              Icon={SendIcon}
            >
              Send
            </Button>
          </div>
        </div>
        <div className="chat-input-bottom-left-area">
          {renderLanguageSelectDropDown}
          {typingUser && (
            <Typing
              firstName={typingUser?.firstName ?? 'test'}
              lastName={typingUser?.lastName ?? 'test'}
            />
          )}
        </div>
      </div>
    </>
  );
};

export { ChatInput as ChatInputDis };

ChatInput.propTypes = {
  previewFile: PropTypes.string.isRequired,
  uploadFileUrl: PropTypes.string.isRequired,
  participants: PropTypes.arrayOf(string).isRequired,
  handleFileSelectChange: PropTypes.func.isRequired,
  scheduledMessages: PropTypes.arrayOf(Object).isRequired,
  inTranslationMode: PropTypes.bool.isRequired,
  languagePreference: PropTypes.string.isRequired,
  typingUser: PropTypes.instanceOf(Object),
  cancelScheduledMessage: PropTypes.func.isRequired,
  chatId: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  activeChatRecipient: PropTypes.instanceOf(Object).isRequired,
  newTyping: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  loadedTemplateError: PropTypes.bool,
  uploadFileIsInProgress: PropTypes.bool.isRequired,
  uploadFileError: PropTypes.string.isRequired,
  verifiedUser: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setCaseLanguage: PropTypes.func.isRequired,
  queueMessage: PropTypes.func,
  setTemplateTrayIsOpen: PropTypes.func.isRequired,
};

ChatInput.defaultProps = {
  loadedTemplateError: false,
  queueMessage: () => {},
  typingUser: null,
};

export const mapDispatchToProps = {
  newTyping,
  sendMessage,
  clearFileUploadStatus,
  cancelUpload,
  setActiveChat,
  setCaseLanguage,
  showSendFileModal,
  cancelScheduledMessage,
  setTemplateTrayIsOpen,
};

const mapStateToProps = (state, ownProps) => selector(state, ownProps);

export default connect(mapStateToProps, mapDispatchToProps)(ChatInput);
