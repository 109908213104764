export const TOTAL_LOSS = 'TOTAL_LOSS';
export const DROPDOWN_LABEL_MARK_ISSUES_COMPLETE = 'Mark Release Issues Complete';

export const COPART_SELLER_PORTAL = 'https://seller.copart.com/lotdisplay/';

export const TRUNCATE_TEXT_LENGTH = 250;

export const ruleSettingsCopy = {
  SUGGEST_TEMPLATE: 'Suggest Template',
  SEND_TEMPLATE: 'Automatically Send',
  OWPIP: 'Remove Belongings',
  VRRLR: 'Verbal Release',
  WRRLR: 'Written Release',
  Shop: 'Vehicle at shop',
  UNCNT: 'Unable to Contact',
  Residence: 'Vehicle at residence',
  RELEASE_ISSUE_CREATED: 'Release Issue Created',
  RELEASE_ISSUE_RESOLVED: 'Release Issue Resolved',
  TRANSTART: 'Assignment Created',
  VEHCHKIN: 'Vehicle Checked In',
  CASE_EVENT_RECEIVED: 'Case Event Received',
};

export const defaultRuleSettingsState = { id: '', rules: {} };

export const defaultActiveMessageTemplateState = {
  id: '',
  ruleId: '',
  showModal: false,
  body: '',
  displayName: '',
  tokens: [],
};

export const defaultActiveRuleState = {
  ruleId: '',
  ruleName: '',
  enabled: false,
  showModal: false,
  rule: {
    conditions: [],
    sendType: null,
  },
  messageTemplate: {
    id: '',
    displayName: '',
    body: '',
    tokens: [],
  },
};

export const PROGRESS_STEP_STATUS_IN_PROGRESS = 'INPROGRESS';
export const PROGRESS_STEP_STATUS_COMPLETE = 'COMPLETE';
export const PROGRESS_STEP_STATUS_NOT_STARTED = 'NOTSTARTED';
export const RELEASE_ISSUE_STATE_OPENED = 'Opened';
export const RELEASE_ISSUE_STATE_COPART_ASSIGNED = 'Copart Assigned';
export const RELEASE_ISSUE_STATE_WAITING_FOR_RELEASE = 'Waiting for Release';
export const BLOCKING_ISSUES = ['VRRLR', 'WRRLR', 'OWPIP'];
export const EVENT_TYPES = {
  VEHCHKIN: 'total_loss.vehicle_checked_in',
  RELEASE_COMPLETED: 'total_loss.release_issue.completed',
  RELEASE_UPDATED: 'total_loss.release_issue.updated',
  RELEASE_RESOLVED: 'total_loss.release_issue.resolved',
};
export const RELEASE_STATUS = {
  ACTIVE: 'ACTIVE',
  RESOLVED: 'RESOLVED',
  COMPLETED: 'COMPLETE',
};

// assist events
export const ASSIST_RELEASE_ISSUE_CREATED = 'RELEASE_ISSUE_CREATED';
export const ASSIST_RELEASE_ISSUE_UPDATED = 'RELEASE_ISSUE_UPDATED';
