import { get } from 'lodash';
import { getUserId } from './cookies';

const LAST_ACTIVE_CHAT = 'LAST_ACTIVE_CHAT';
const PREVIOUS_PERMISSIONS = 'PREVIOUS_PERMISSIONS';

export function saveLastActiveChat(lastActiveChat) {
  const data = {
    lastActiveChat,
    userId: getUserId(),
  };
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(LAST_ACTIVE_CHAT, JSON.stringify(data));
  }
}

export function getLastActiveChat(userId) {
  const str = typeof localStorage !== 'undefined' ? (localStorage.getItem(LAST_ACTIVE_CHAT) || '') : '';
  try {
    const data = JSON.parse(str);
    if (data.userId === userId) {
      return data.lastActiveChat;
    }
    return '';
  } catch (e) {
    return '';
  }
}

export function savePreviousPermissions(userId, permissions) {
  const data = {
    permissions,
    userId,
  };
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(PREVIOUS_PERMISSIONS, JSON.stringify(data));
  }
}

export function getPreviousPermissions(userId) {
  const str = typeof localStorage !== 'undefined' ? (localStorage.getItem(PREVIOUS_PERMISSIONS) || '') : '';
  try {
    const data = JSON.parse(str);
    if (data.userId === userId) {
      return data.permissions;
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function saveToLocalStorage(key, value) {
  if (typeof key !== 'string') {
    console.log('Cannot save to local storage');
  } else {
    if (typeof localStorage !== 'undefined') {
      const valueType = typeof value;
      localStorage.setItem(key, valueType === 'object' || valueType === 'array' ? JSON.stringify(value) : value);
      return true;
    }
    console.log('Cannot save to local storage');
  }
  return false;
}

export function getLocalStorageValue(key) {
  if (typeof key !== 'string') {
    console.log('Cannot get item from local storage');
  } else {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    console.log('Cannot get item from local storage');
  }
  return '';
}

export function cleanLocalStorage() {
  if (typeof localStorage !== 'undefined') {
    localStorage.clear();
  }
}

const retryPatternSync = (func, waitTime, arg) => async () => {
  let result;
  let firstRun = true;
  const waitFunc = () => new Promise((resolve) => {
    setTimeout(resolve, waitTime);
  });
  do {
    if (!firstRun) await waitFunc();
    firstRun = false;
    result = func(arg);
  } while (!result);
  return get(result, arg);
};

const getTokenObject = (tokenName) => {
  const oktaTokenStorage = getLocalStorageValue('okta-token-storage');
  const tokens = JSON.parse(oktaTokenStorage);
  const token = get(tokens, `${tokenName}`);
  return token;
};

export const getAccessToken = retryPatternSync(getTokenObject, 500, 'accessToken');
