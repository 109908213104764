/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React, { useState, useMemo, useCallback } from 'react';
import { MultiSelectDropdownMenu } from '@himarley/unity';
import { useDispatch, useSelector } from 'react-redux';
import UserPhoto2 from '../../../UserPhoto2/UserPhoto2';
import ArchiveIcon from '../../../../../images/icons/archive.svg';
import SelectChecked from '../../../../../images/icons/select-checked-icon.svg';
import UserManagementIcon from '../../../../../images/icons/user-management.svg';
import DocumentIcon from '../../../../../images/icons/document.svg';
import SurveyIcon from '../../../../../images/icons/survey.svg';
import ResendIcon from '../../../../../images/icons/chat-bubbles.svg';
import LockClosedIcon from '../../../../../images/icons/lock-closed.svg';
import AuditLogIcon from '../../../../../images/icons/auditLog.svg';
import LargeLookContent from '../../../LargeLookContent/LargeLookContent';
import LobIcon from '../../../Settings/CompanyHierarchy/LoBIcon';
import FolderIcon from '../../../icons/FolderIcon';
import { capitalizeEachWord } from '../../../../helpers/format';
import { useCheckPermissions } from '../../../../helpers/common';
import { localToRemoteUrl } from '../../../../helpers/urls';
import SurveyModal from './SurveyModal/SurveyModal';
import './ChatHeader.less';
import { setReleaseIssueModalOpen, toggleConfirmation, setReleaseIssueModalError } from '../../../../actions/ui';
import ReleaseIssuesModal from '../../../Form/ReleaseIssues/ReleaseIssuesModal';
import {
  clearActiveJob,
  sendSurvey,
  cleanSendSurvey,
  jobPutRequest,
  completeReleaseIssues,
} from '../../../../actions/job';
import {
  DISABLE_CREATE_CASE,
  CREATE_CASE_FROM_CHATS_PAGE,
  FEATURE_FLAG_TOTAL_LOSS_ASSIST,
} from '../../../../constants/permissions';

import chatModuleSelector from '../selector';
import { isReleaseIssueOpen } from '../../../../helpers/copart-utils';
import { DROPDOWN_LABEL_MARK_ISSUES_COMPLETE } from '../../../../constants/copart-constants';
import { lookNumberConfig, getLookerEnvironment } from '../../../../helpers/analytics';

const ChatHeader = () => {
  const {
    releaseIssues = [],
    isReleaseIssueModalOpen = false,
    selectedReleaseIssue = '',
    jobId,
    jobNumber,
    lineOfBusiness,
    permissions,
    recipient,
    surveyRequest,
    chatPdfLink,
    assignedOperator,
    isClosed,
    customerPdfLink,
    job,
    isOpenIssuesRemaining,
  } = useSelector(chatModuleSelector);
  const isCreateCaseEnabled = !useCheckPermissions([DISABLE_CREATE_CASE]);
  const isCreateCaseButtonOnChatsEnabled = useCheckPermissions([
    CREATE_CASE_FROM_CHATS_PAGE,
  ]);
  const isCreateCaseButtonEnabled = useMemo(() => isCreateCaseEnabled && isCreateCaseButtonOnChatsEnabled, [isCreateCaseEnabled, isCreateCaseButtonOnChatsEnabled]);
  const filteredReleaseIssues = useMemo(() => releaseIssues.filter((issue) => isReleaseIssueOpen(issue)), [releaseIssues]);
  const dispatch = useDispatch();
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showLargeLook, setShowLargeLook] = useState(false);

  const handleToggleOpenCase = useCallback(() => {
    const theJob = { ...job, isOpen: isClosed };
    const isReopeningJob = isClosed;
    dispatch(jobPutRequest(theJob, isReopeningJob));
    if (!isClosed) {
      dispatch(clearActiveJob(job.id));
      window.history.pushState(null, null, localToRemoteUrl('/chats'));
    }
  }, [dispatch, job, isClosed]);

  const openLargeLook = () => {
    setShowLargeLook(true);
  };

  const closeLargeLook = () => {
    setShowLargeLook(false);
  };

  const openSendSurvey = () => {
    setShowSurveyModal(true);
  };

  const closeSendSurvey = () => {
    dispatch(cleanSendSurvey());
    setShowSurveyModal(false);
  };
  const operatorHasAuditLogAccess = useMemo(() => permissions.includes('AUDIT_LOG'), [permissions]);
  const operatorHasUnredactedAccess = useMemo(() => permissions.includes('REDACTION_VIEW_ACCESS'), [permissions]);
  const isTotalLossEnabled = useMemo(() => permissions.includes(FEATURE_FLAG_TOTAL_LOSS_ASSIST), [permissions]);
  const isTwoWayEnabled = process.env.COPART_TWO_WAY_ENABLED;
  const shouldShowMarkIssuesComplete = isTotalLossEnabled && isTwoWayEnabled;
  const isRedactionOrgEnabled = useMemo(() => permissions.includes(
    'FEATURE_FLAG_UI_REDACTION',
  ), [permissions]);
  const menuItems = useMemo(() => {
    const caseAction = [
      {
        id: `${!isClosed ? 'chatMenu-closecase' : 'chatMenu-reopencase'}`,
        label: !isClosed ? 'Close Case' : 'Re-open Case',
        avatar: !isClosed ? <ArchiveIcon /> : <FolderIcon />,
        handler: () => handleToggleOpenCase(),
      },
    ];
    const auditLogButtons = [
      {
        id: 'chatMenu-auditLog',
        avatar: <AuditLogIcon />,
        label: 'Audit Log',
        handler: (e) => openLargeLook(e),
      },
    ];
    const unredactedTrascriptButtons = [
      {
        id: 'chatMenu-casetrans',
        avatar: <DocumentIcon />,
        label: 'Case Transcript',
        handler: () => window.open(localToRemoteUrl(chatPdfLink)),
      },
      {
        id: 'chatMenu-usertrans',
        avatar: <UserManagementIcon />,
        label: 'Customer Transcript',
        handler: () => window.open(localToRemoteUrl(customerPdfLink)),
      },
    ];
    const redactionActions = [
      {
        id: 'chatMenu-casetrans-redacted',
        avatar: <LockClosedIcon />,
        label: 'Redacted Case Transcript',
        handler: () => window.open(localToRemoteUrl(`${chatPdfLink}&redacted=true`)),
      },
      {
        id: 'chatMenu-usertrans-redacted',
        label: 'Redacted Customer Transcript',
        avatar: <LockClosedIcon />,
        handler: () => window.open(localToRemoteUrl(`${customerPdfLink}&redacted=true`)),
      },
    ];
    const copartActions = [
      {
        id: 'chatMenu-resolve-release-issues',
        label: DROPDOWN_LABEL_MARK_ISSUES_COMPLETE,
        avatar: <SelectChecked className="chatMenu-resolve-release-issues" />,
        handler: () => dispatch(setReleaseIssueModalOpen({ isOpen: true })),
      },
    ];
    const resendWelcomeAction = [{
      id: 'chatMenu-resendWelcome',
      avatar: <ResendIcon />,
      label: 'Resend Opt-In Text',
      handler: () => dispatch(
        toggleConfirmation({
          id: 'resendwelcome',
          isOpen: true,
          selectedId: jobId,
        }),
      ),
    }];
    const sendSurveyAction = [{
      id: 'chatMenu-sendSurvey',
      avatar: <SurveyIcon />,
      label: 'Send Survey',
      handler: (e) => openSendSurvey(e),
    }];
    return [
      ...(!isRedactionOrgEnabled || operatorHasUnredactedAccess ? unredactedTrascriptButtons : []),
      ...(isRedactionOrgEnabled ? redactionActions : []),
      ...(operatorHasAuditLogAccess ? auditLogButtons : []),
      ...((isOpenIssuesRemaining && shouldShowMarkIssuesComplete) ? copartActions : []),
      ...((!recipient.verified) ? resendWelcomeAction : []),
      ...((recipient.verified && assignedOperator) ? sendSurveyAction : []),
      ...caseAction,
    ];
  }, [isClosed, operatorHasUnredactedAccess, isRedactionOrgEnabled, operatorHasAuditLogAccess, isOpenIssuesRemaining, shouldShowMarkIssuesComplete, recipient.verified, assignedOperator, handleToggleOpenCase, chatPdfLink, customerPdfLink, dispatch, jobId]);

  const lookNumber = lookNumberConfig[getLookerEnvironment()].auditLog;
  const handleSubmitReleaseIssues = async (data) => {
    try {
      await dispatch(completeReleaseIssues({ jobId, data })).unwrap();
      dispatch(setReleaseIssueModalOpen({ isOpen: !isReleaseIssueModalOpen }));
    } catch (error) {
      dispatch(setReleaseIssueModalError());
    }
  };

  return (
    <div className="chat-component-header">
      {showLargeLook && (
      <LargeLookContent
        caseId={jobId}
        closeLook={() => closeLargeLook()}
        lookNumber={lookNumber}
      />
      )}
      <SurveyModal
        closeSendSurvey={() => closeSendSurvey()}
        sendSurvey={() => dispatch(
          sendSurvey(job),
        )}
        surveyRequest={surveyRequest}
        isAssignedJob={!!assignedOperator}
        showSurvey={showSurveyModal}
      />
      <ReleaseIssuesModal
        show={isReleaseIssueModalOpen}
        setShow={() => dispatch(setReleaseIssueModalOpen({ isOpen: !isReleaseIssueModalOpen }))}
        releaseIssueData={filteredReleaseIssues}
        onSubmitReleaseIssues={handleSubmitReleaseIssues}
        selectedReleaseIssue={selectedReleaseIssue}
      />
      <div className="chat-component-inner">
        <div className="chat-component-avatar">
          {lineOfBusiness?.subType ? (
            <LobIcon
              name={lineOfBusiness?.subType}
              className="line-of-business-icon"
            />
          ) : (
            <UserPhoto2
              id={recipient.id}
              imageUrl={recipient.imageUrl}
              name={recipient.name}
              className="header-user-photo"
              isRecipient
            />
          )}
        </div>
        <div className="chat-component-text-wrap">
          <div className="chat-component-text-name">{recipient.name}</div>
          <div
            className="chat-component-text-id"
            data-testid="chat-component-text-id"
          >
            {lineOfBusiness?.displayName
              ? `${capitalizeEachWord(lineOfBusiness?.displayName)}:`
              : 'ID'}
            {' '}
            {jobNumber}
            {' '}
            -
            {' '}
            <span className="chat-component-assigned-header">
              {assignedOperator
                ? `Assigned to ${assignedOperator}`
                : 'Unassigned'}
            </span>
          </div>
        </div>
        <div
          className={
              isCreateCaseButtonEnabled
                ? 'chat-component-assigned chat-component-assigned-create-case-btn'
                : 'chat-component-assigned chat-component-assigned-no-create-case-btn'
            }
        >
          <MultiSelectDropdownMenu
            isFlush={false}
            menuItems={menuItems}
            onSelectItem={(item) => item.handler()}
            selectedItemLabel="Actions"
            selectedItemsMap={new Map()}
            triggerClassName="trigger-class"
            contentClassName="content-class"
          />
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
