import React from 'react';
import {
  CheckmarkIcon,
  PaperAirplaneIcon,
  LightbulbIcon,
  CheckCircleIcon,
  StopIcon,
  Button,
} from '@himarley/unity';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './IntegrationSettingsContainer.module.less';

import { ruleSettingsCopy, TRUNCATE_TEXT_LENGTH } from '../../../constants/copart-constants';
import { generateReadableBody } from '../../Form/MessageTemplate/helpers';

const IntegrationRule = ({
  ruleId,
  ruleName,
  templateId,
  isEnabled,
  messageTemplateText,
  messageTemplateTokens,
  messageTemplateDisplayName,
  toggleTemplateModal,
  toggleRuleModal,
  rule,
}) => {
  const { conditions, event } = rule;
  const tokens = useSelector(({ templates }) => templates.keys);
  const { text: textData } = generateReadableBody(
    tokens,
    { body: messageTemplateText, tokens: messageTemplateTokens, truncateAt: TRUNCATE_TEXT_LENGTH },
  );

  function formatText(text, messageTemplateTokens) {
    const formattedText = [];
    let offset = 0;
    messageTemplateTokens.forEach((token) => {
      const tokenIndex = text.indexOf(`$${token.token}`, offset);
      if (tokenIndex > -1) {
        formattedText.push((
          <>
            {text.substring(offset, tokenIndex)}
            <b>{`$${token.token}`}</b>
          </>
        ));
        offset = tokenIndex + token.token.length + 1;
      }
    });
    if (offset < text.length) {
      formattedText.push((
        <>
          {text.substring(offset)}
        </>
      ));
    }
    return formattedText;
  }

  return (
    <div
      data-testid={`ruleset-rule-container-${ruleId}`}
      className={`${styles.integrationRuleContainer} ${(isEnabled) ? styles.padding16 : ''}`}
    >
      <div className={(!isEnabled) ? styles.flexRowDisabled : styles.flexRowEnabled}>
        <h1 data-testid="integration-rule-name" className={`${styles.ruleName}  ${(!isEnabled) ? styles.disabledColor : ''}`}>{ruleName}</h1>
        <div className={styles.ruleStatus}>
          {(isEnabled) ? (
            <div data-testid="enabled-rule-status" className={`${styles.enabledStatus} ${styles.enabledColor}`}>
              <CheckCircleIcon color="#51d6b8" className={styles.enabledIcon} />
              Enabled
            </div>
          ) : (
            <div data-testid="disabled-rule-status" className={`${styles.enabledStatus} ${styles.disabledColor}`}>
              <StopIcon color="#5E7388" className={styles.enabledIcon} />
              Disabled
            </div>
          ) }
          <Button
            type="outline"
            testId={`edit-message-rule-button-${ruleId}`}
            className={styles.editRuleBtn}
            onClick={
            () => toggleRuleModal({
              ruleId,
              ruleName,
              rule: {
                conditions: rule.conditions.all,
                sendType: rule.event.type,
              },
              enabled: isEnabled,
              messageTemplate: {
                id: templateId,
                body: messageTemplateText,
                displayName: messageTemplateDisplayName,
                tokens: messageTemplateTokens,
              },
            })
}
          >
            Edit Rule
          </Button>
        </div>
      </div>
      {isEnabled && (
      <div data-testid="enabled-rule-content">
        <div className={styles.flexRowIcons}>
          <div style={{ width: '50%' }}>
            {conditions.all.map((condition) => {
              // condition values are either a string or an array of strings
              if (typeof condition.value === 'string') {
                return (
                  <div key={condition.value} className={styles.condition}>
                    <CheckmarkIcon color="#5E7388" className={styles.ruleIcon} />
                    {ruleSettingsCopy[condition.value]}
                  </div>
                );
              }
              return condition.value.map((conditionValue) => (
                <div key={conditionValue} className={styles.condition}>
                  <CheckmarkIcon color="#5E7388" className={styles.ruleIcon} />
                  {ruleSettingsCopy[conditionValue]}
                </div>
              ));
            })}
          </div>
          <div className={styles.condition} style={{ width: '50%' }}>
            {ruleSettingsCopy[event.type]?.includes('Send') ? <PaperAirplaneIcon color="#5E7388" className={styles.ruleIcon} /> : <LightbulbIcon color="#5E7388" className={styles.ruleIcon} />}
            {ruleSettingsCopy[event.type]}
          </div>
        </div>
        <div className={`${styles.templatePreviewMode} ${styles.flexRowEnabled}`}>
          <div className={styles.templateText} data-testid="enabled-rule-text">
            <p>{formatText(textData, messageTemplateTokens)}</p>
          </div>
          <Button
            type="outline"
            testId={`edit-message-template-button-${ruleId}`}
            className={styles.editTemplateBtn}
            onClick={
                () => toggleTemplateModal({
                  templateId,
                  ruleId,
                  messageTemplateTitle: messageTemplateDisplayName,
                  messageTemplateText,
                  messageTemplateTokens,
                })
              }
          >
            Edit Template
          </Button>
        </div>
      </div>
      )}
    </div>
  );
};

IntegrationRule.propTypes = {
  ruleId: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  messageTemplateText: PropTypes.string.isRequired,
  messageTemplateDisplayName: PropTypes.string.isRequired,
  ruleName: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  toggleTemplateModal: PropTypes.func.isRequired,
  toggleRuleModal: PropTypes.func.isRequired,
  messageTemplateTokens: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rule: PropTypes.shape({
    conditions: PropTypes.shape({
      all: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string),
        ]),
      })),
    }),
    event: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default IntegrationRule;
