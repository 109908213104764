import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { extractTokens } from '@app/helpers/common';
import { getKeys } from '@app/slices/templates';
import IntegrationSettings from './IntegrationSettings';
import styles from './IntegrationSettingsContainer.module.less';
import MessageTemplateForm from '../../Form/MessageTemplate/MessageTemplateForm';
import RuleForm from '../../Form/Rule/rule-form';
import { keyById, buildRequestOptions } from './utils';
import useSecureRequest from '../../../hooks/secure-request';
import secureRequest from '../../../helpers/secure-request';
import {
  TOTAL_LOSS, defaultActiveMessageTemplateState, defaultActiveRuleState, defaultRuleSettingsState,
} from '../../../constants/copart-constants';
import { showSnackbar } from '../../../actions/notification';
import { GET_RULESET } from '../../../constants/endpoints';

const IntegrationSettingsContainer = () => {
  const { requestState, parsedBody } = useSecureRequest(GET_RULESET(TOTAL_LOSS), { method: 'GET' });
  const [ruleSettings, setRuleSettings] = useState(defaultRuleSettingsState);

  const [activeMessageTemplate, setActiveMessageTemplate] = useState(
    defaultActiveMessageTemplateState,
  );
  const [activeRule, setActiveRule] = useState(
    defaultActiveRuleState,
  );
  const [initialRuleSettings, setInitialRuleSettings] = useState(defaultRuleSettingsState);
  const isRuleSettingsChanged = useMemo(
    () => JSON.stringify(initialRuleSettings.rules) !== JSON.stringify(ruleSettings.rules),
    [ruleSettings, initialRuleSettings],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKeys());
  }, [dispatch]);

  useEffect(() => {
    if (requestState === 'success') {
      const { rules, id, defaultVersion } = parsedBody;
      const rulesSorted = sortRules(rules);
      setRuleSettings({ id, defaultVersion, rules: { ...keyById(rulesSorted, id) } }); // initial state set
      setInitialRuleSettings({ id, defaultVersion, rules: { ...keyById(rulesSorted, id) } });
    }
  }, [requestState, parsedBody]);

  useEffect(() => { if (isRuleSettingsChanged) handleSubmitRules(); }, [isRuleSettingsChanged]);

  const sortRules = (rules) => rules.sort((a, b) => {
    if (a.enabled !== b.enabled) {
      return b.enabled - a.enabled;
    }
    // If 'enabled' is the same, sort by 'ruleName' in ascending order
    const aRuleName = a.ruleName || a.messageTemplate.displayName || '';
    const bRuleName = b.ruleName || b.messageTemplate.displayName || '';
    return aRuleName.localeCompare(bRuleName);
  });

  const toggleTemplateModal = ({
    templateId: id,
    ruleId,
    messageTemplateTitle: displayName,
    messageTemplateText: body,
    messageTemplateTokens: tokens,
  }) => {
    const activeMessageTemplateData = id ? {
      id,
      ruleId,
      showModal: true,
      body,
      displayName,
      tokens,
    } : defaultActiveMessageTemplateState;
    setActiveMessageTemplate(activeMessageTemplateData);
  };

  const toggleRuleModal = ({
    ruleId,
    ruleName,
    rule,
    enabled,
    messageTemplate,
  }) => {
    const activeRuleData = ruleId ? {
      ruleId,
      ruleName,
      enabled,
      showModal: true,
      rule: {
        conditions: rule.conditions,
        sendType: rule.sendType,
      },
      messageTemplate: {
        id: messageTemplate.id,
        displayName: messageTemplate.displayName,
        body: messageTemplate.body,
        tokens: messageTemplate.tokens,
      },
    } : defaultActiveRuleState;
    setActiveRule(activeRuleData);
  };

  const handleSaveMessageTemplate = (formData) => {
    const { ruleId } = activeMessageTemplate;
    const { body, tokens } = extractTokens(formData.body); // format readable tokens as ${TOKEN}
    const formattedFormData = {
      displayName: formData.displayName,
      body,
      tokens,
    };
    const newRuleSettings = {
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          messageTemplate: {
            ...ruleSettings.rules[ruleId].messageTemplate,
            ...formattedFormData,
          },
        },
      },
    };

    setRuleSettings(newRuleSettings);
    setActiveMessageTemplate(defaultActiveMessageTemplateState);
  };

  const handleSaveRule = (formData) => {
    const { ruleId } = activeRule;
    const {
      ruleName, enabled, messageTemplateName, messageTemplateBody,
    } = formData;
    const { body, tokens } = extractTokens(messageTemplateBody); // format readable tokens as ${TOKEN}

    setRuleSettings({
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          enabled,
          ruleName,
          messageTemplate: {
            ...ruleSettings.rules[ruleId].messageTemplate,
            displayName: messageTemplateName,
            body,
            tokens,
          },
        },
      },
    });
    setActiveRule(defaultActiveRuleState);
  };

  const handleSubmitRules = async () => {
    // initial state set
    const { url, options } = buildRequestOptions(ruleSettings);
    const response = await secureRequest(url, options);
    if (response.ok) {
      const { rules, id } = await response.json();
      const rulesSorted = sortRules(rules);
      setRuleSettings({ id, rules: { ...keyById(rulesSorted, id) } });
      setInitialRuleSettings({ id, rules: { ...keyById(rulesSorted, id) } });
      dispatch(showSnackbar({ text: 'Rules and Settings saved', isError: false }));
    } else {
      // Some Error handling here
      setRuleSettings(initialRuleSettings);
      dispatch(showSnackbar({ text: 'Error submitting rules', isError: true }));
    }
  };

  return (
    <div data-testid="integration-settings-container">
      <header>
        <h1>Integration Settings</h1>
      </header>
      <p>Configure rules and settings per software integration</p>
      <RuleForm
        show={activeRule.showModal}
        toggleModal={toggleRuleModal}
        handleSubmit={handleSaveRule}
        ruleData={activeRule}
      />
      <MessageTemplateForm
        show={activeMessageTemplate.showModal}
        method="update"
        toggleModal={toggleTemplateModal}
        handleSubmit={handleSaveMessageTemplate}
        templateData={activeMessageTemplate}
      />
      <IntegrationSettings
        className={styles.accordionContent}
        ruleSettings={ruleSettings.rules}
        toggleTemplateModal={toggleTemplateModal}
        toggleRuleModal={toggleRuleModal}
      />
    </div>
  );
};

export default IntegrationSettingsContainer;
