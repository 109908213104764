/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { FileDrop } from 'react-file-drop';

import {
  TextAreaInput, Modal, Button, ButtonRow, Tabs, Tab,
} from '@himarley/unity';
import User from '@app/models/user';
import UserListItem from '../UserListItem';
import withLoading from '../../../../HigherOrderComponents/withLoading';
import withLazyLoad from '../../../../withLazyLoad/withLazyLoad';
import { groupType, operatorType } from '../../../../../models/marleyTypes';
import {
  lazyLoad,
  createItem,
  updateItem,
} from '../../../../../actions/common';
import { getChunkedUsersByEmail } from '../../../../../actions/users';
import Ghosts from '../../../../elements/Ghosts/Ghosts';
import TextInputLine from '../../../../elements/form/TextInput/TextInputLine';
import TitleText from '../../../../elements/text/TitleText/TitleText';
import SearchBar from '../../../../SearchBar/SearchBar';
import { copyTextToClipboard } from '../../../../../helpers/common';
import useForm from '../../../../elements/hooks/useForm';
import UserList from './UserList';
// eslint-disable-next-line import/no-named-as-default
import GroupList from './GroupList';

import './CreateGroup.less';
import GroupListItem from '../GroupListItem';

export const validation = [
  { id: 'name', required: true },
  { id: 'members', required: true },
  { id: 'leads', required: true },
];

const TAB_EVENT_KEYS = {
  users: 'users',
  groups: 'groups',
};

const AddMultipleUsersLink = () => {
  const tooltip = (
    <Tooltip id="tooltip">
      Upload a .txt or .csv file with user emails in any format or drag file
      into area below
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <a className="add-multiple-users-link">+ import existing users</a>
    </OverlayTrigger>
  );
};

const CreateGroup = ({
  group,
  show,
  modifyQuery,
  toggleModal,
  getChunkedUsersByEmail: getChunkedUsersByEmailFn,
  users,
  isLoading,
  allErrors,
  increaseDataSet,
  createItem: createItemFn,
  updateItem: updateItemFn,
  groupsMap,
  copyTextToClipboard: copyTextToClipboardFn,
}) => {
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [foundEmailError, setFoundEmailError] = useState(false);
  const [missingEmails, setMissingEmails] = useState([]);
  const [isDescriptionInputShown, setIsDescriptionInputShown] = useState(false);
  const [activeTab, setActiveTab] = useState(TAB_EVENT_KEYS.users);
  const { handleFormChange, formObject, isEdit } = useForm({
    validation,
    item: group || {},
  });
  const {
    name, description, members, leads, subgroups,
  } = formObject || {};

  const fileInput = useRef();
  const descriptionInput = useRef(null);

  const onSearchTextChange = (e) => {
    const searchText = _.get(e, 'target.value');
    modifyQuery({ searchText });
  };

  const handleSubmit = () => {
    const leads = _.get(formObject, 'leads', []).map((m) => m.id);
    const members = _.get(formObject, 'members', []).map((m) => m.id);
    const subgroups = _.get(formObject, 'subgroups', []).map((m) => m?._id);

    const changeObject = {
      ...formObject,
      leads,
      members,
      subgroups,
    };

    if (!isEdit) {
      createItemFn(groupType.allUpperCase(), changeObject, 'group')
        .then(() => {
          toggleModal();
        })
        .catch((error) => {
          setFoundEmailError(error.message);
        });
    } else {
      updateItemFn(
        groupType.allUpperCase(),
        changeObject,
        formObject.id,
        'group',
      )
        .then(() => {
          toggleModal();
        })
        .catch((error) => {
          setFoundEmailError(error.message);
        });
    }
  };

  const extractEmails = (text) => text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

  const handleFile = async (e) => {
    if (isLoadingFile) { return; }
    setIsLoadingFile(true);
    const reader = new FileReader();
    setMissingEmails([]);
    reader.onload = async (e) => {
      const textEmails = extractEmails(e.target.result).map((email) => email.trim().toLowerCase());
      const chunks = await getChunkedUsersByEmailFn(textEmails);
      const foundUsers = chunks.flatMap((i) => i.users || []);
      const foundIds = foundUsers.map((u) => u._id);
      const foundEmails = foundUsers.map((u) => u.email.trim().toLowerCase());
      const notFoundEmails = _.difference(textEmails, foundEmails);
      setMissingEmails(notFoundEmails);

      const existingIds = (members || []).map((m) => m.id);
      const allIds = _.union(existingIds, foundIds);
      const numDupeIds = foundIds.length + existingIds.length - allIds.length;
      const intersectingIds = _.intersection(existingIds, foundIds);
      const newIdsToBeAdded = new Set(_.difference(foundIds, intersectingIds));
      const newUsersToBeAdded = foundUsers.filter(
        (u) => newIdsToBeAdded.has(u._id),
      ).map((u) => new User(u));

      const errors = [];

      if (numDupeIds > 0) {
        errors.push(
          `Found ${numDupeIds} duplicate profile${numDupeIds > 1 ? 's' : ''}`,
        );
      }
      if (notFoundEmails.length > 0) {
        errors.push(
          `Could not find ${notFoundEmails.length} email${
            notFoundEmails.length > 1 ? 's' : ''
          } in our system`,
        );
      }
      if (foundEmails.length === 0) errors.push('Could not find any emails in the document');
      if (errors.length > 0) setFoundEmailError(errors.join('. '));

      handleFormChange({
        members: [
          ...(members || []),
          ...newUsersToBeAdded,
        ],
      });

      setIsLoadingFile(false);
      fileInput.current.value = '';
    };
    reader.readAsText(e.target.files[0]);
  };

  const toggleAndClearModal = (s) => {
    if (toggleModal) toggleModal(s);
  };

  useEffect(() => {
    handleFormChange(group);
  }, [group]);

  const handleDescriptionClick = () => {
    setIsDescriptionInputShown(true);
  };

  useEffect(() => {
    if (descriptionInput.current) {
      descriptionInput.current?.focus();
    }
  }, [isDescriptionInputShown]);

  const membersAndLeads = useMemo(
    () => _.union(members, leads, subgroups),
    [leads, members, subgroups],
  );

  const updateActiveList = useCallback(
    (groupParam) => {
      const changes = _.set(
        {},
        'subgroups',
        [...subgroups].filter((l) => l?._id !== groupParam?._id),
      );
      handleFormChange(changes);
    },
    [handleFormChange, subgroups],
  );

  return (
    <Modal
      className="create-group-modal" /* todo: remove and generalize */
      data-test="create-group-modal"
      title={`${isEdit ? 'Edit' : 'Create'} Group`}
      show={show}
      toggleModal={toggleAndClearModal}
    >
      {!isEdit && (
        <>
          <div className="nameDescriptionWrap">
            <TextInputLine
              label="Group Name "
              placeholder="Enter Group Name"
              data-jest="form-input-name"
              value={name}
              required
              onChange={(e) => handleFormChange({ name: _.get(e, 'target.value') })}
            />
            {!isDescriptionInputShown && (
              <button
                data-testid="addDescription"
                type="button"
                onClick={handleDescriptionClick}
                className="add-multiple-users-link"
              >
                + Add Description
              </button>
            )}
          </div>
          {isDescriptionInputShown && (
            <TextAreaInput
              label="Group Description"
              data-jest="description-textarea"
              placeholder="Add group description..."
              onChange={(e) => handleFormChange({ description: _.get(e, 'target.value') })}
              value={description}
              maxLength={150}
              ref={descriptionInput}
              inputRef={descriptionInput}
            />
          )}
          <TitleText
            titleType="subheader"
            title="Add Members"
            text="Select existing users or groups to add. You can also assign group leads."
          />
        </>
      )}
      <div className="add-members">
        <div className="list-column">
          <Tabs type="content" defaultActiveKey={TAB_EVENT_KEYS.users}>
            <Tab
              eventKey={TAB_EVENT_KEYS.users}
              title="Users"
              isActive={activeTab === TAB_EVENT_KEYS.users}
              onClick={() => setActiveTab(TAB_EVENT_KEYS.users)}
            >
              <div className="column-group">
                <SearchBar
                  placeholder="Search user to add"
                  onChange={onSearchTextChange}
                />
                {isLoading ? (
                  <Ghosts numItems={6} className="item" />
                ) : (
                  <UserList
                    increaseDataSet={increaseDataSet}
                    users={users}
                    members={members}
                    leads={leads}
                    handleFormChange={handleFormChange}
                  />
                )}
              </div>
            </Tab>
            <Tab
              eventKey={TAB_EVENT_KEYS.groups}
              title="Groups"
              isActive={activeTab === TAB_EVENT_KEYS.groups}
              onClick={() => setActiveTab(TAB_EVENT_KEYS.groups)}
            >
              <div className="column-group">
                <GroupList
                  groupId={group?.id}
                  handleFormChange={handleFormChange}
                  subgroups={subgroups}
                  groupsMap={groupsMap}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
        <div className="list-column">
          <div className="sub-subheader">
            <div>
              Members
              <span className="text-danger"> *</span>
            </div>
            <label aria-label="file import" className="sub-subheader-label" htmlFor="fileInput">
              <AddMultipleUsersLink />
              <input
                type="file"
                id="fileInput"
                ref={fileInput}
                className="hidden-file-input"
                onChange={handleFile}
                data-testid="file-input"
              />
            </label>
          </div>
          <div style={{ overflow: 'auto' }} className="column-group">
            <FileDrop onDrop={(files) => handleFile({ target: { files } })}>
              <div className="file-drop-target-group">+ Add Multiple Users</div>
            </FileDrop>
            {isLoadingFile ? (
              <Ghosts numItems={6} className="item" />
            ) : (
              <div className="membersAndLeadsWrap">
                {membersAndLeads.length === 0 && (
                  <div className="noMembersAndLeadsText">
                    Select Users or Groups
                    <br />
                    from the left to add
                    <br />
                    members to the group.
                  </div>
                )}
                {membersAndLeads.map((u) => {
                  const isLead = (leads || [])
                    .map((l) => l?.id)
                    ?.includes(u?.id);
                  return u?._id ? (
                    <GroupListItem
                      key={u?._id || u?.id}
                      isSelected
                      item={u}
                      updateList={() => updateActiveList(u)}
                      groupsMap={groupsMap}
                    />
                  ) : (
                    <UserListItem
                      key={u?._id || u?.id}
                      isSelected
                      isLead={isLead}
                      item={u}
                      updateList={() => {
                        const activeList = isLead ? leads : members;
                        const changes = _.set(
                          {},
                          isLead ? 'leads' : 'members',
                          [...activeList].filter((l) => l.id !== u.id),
                        );
                        handleFormChange(changes);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="error-row">
        {foundEmailError || allErrors}
        {missingEmails.length > 0 ? (
          <span>
            {' '}
            -
            <a
              className="copy-list-link"
              onClick={copyTextToClipboardFn(missingEmails.join(', '))}
              onKeyDown={copyTextToClipboardFn(missingEmails.join(', '))}
              role="button"
              tabIndex={0}
            >
              copy list
            </a>
          </span>
        ) : null}
      </div>
      <ButtonRow>
        <Button type="outline" onClick={() => toggleAndClearModal(false)}>
          Cancel
        </Button>
        <Button
          type="positive"
          data-test="create-group-button"
          testId="create-group-button"
          disabled={
            (name?.trim().length || 0) === 0 || membersAndLeads.length < 1
          }
          onClick={handleSubmit}
        >
          {isEdit ? 'Update' : 'Create'}
          {' '}
          Group
        </Button>
      </ButtonRow>
    </Modal>
  );
};

CreateGroup.propTypes = {
  allErrors: PropTypes.arrayOf(String),
  createItem: PropTypes.func.isRequired,
  formObject: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    leads: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    members: PropTypes.shape([]),
    subgroups: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  modifyQuery: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  group: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  show: PropTypes.bool.isRequired,
  getChunkedUsersByEmail: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  increaseDataSet: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  groupsMap: PropTypes.shape({}).isRequired,
  copyTextToClipboard: PropTypes.func.isRequired,
};

CreateGroup.defaultProps = {
  allErrors: [],
  formObject: {},
  users: null,
};

export { CreateGroup };

const mapStateToProps = (state) => ({
  users: _.get(state, 'operators.list')?.sort((a, b) => a?.name.localeCompare(b?.name)),
});

const CreateGroupWithFetch = withLazyLoad(CreateGroup, {
  type: operatorType,
  listLocation: 'users',
  defaultQuery: { query: { role: 'operator' } },
});

const CreateGroupFetchFormLoading = withLoading(CreateGroupWithFetch, {
  type: operatorType,
});

export default connect(mapStateToProps, {
  lazyLoad,
  createItem,
  updateItem,
  getChunkedUsersByEmail,
  copyTextToClipboard,
})(CreateGroupFetchFormLoading);
