/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Modal,
  ButtonRow,
  TextInput,
  SlideToggle,
  LightbulbIcon,
  PaperAirplaneIcon,
  Divider,
  CheckmarkIcon,
} from '@himarley/unity';
import React, {
  useRef, useState, useEffect, FC,
} from 'react';
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions';
import { useSelector } from 'react-redux';

import './rule-form.less';
import { MessageTemplate } from '@app/components/Settings/WelcomeFlowSettings/types';
import { StateType } from '@app/types/reducer-state';

import { ruleSettingsCopy } from '../../../constants/copart-constants';
import { generateReadableBody } from '../MessageTemplate/helpers';

const MAX_CHAR_TITLE_LENGTH = 250;
const MAX_CHAR_INPUT_LENGTH = 1400;

interface FormData {
  ruleName: string | undefined;
  enabled: boolean | undefined;
  messageTemplateName: string;
  messageTemplateBody: string;
}

export interface Condition {
  fact: string;
  value: string | Array<string>;
}
export interface RuleData {
  messageTemplate: MessageTemplate;
  id?: string;
  ruleName?: string;
  rule: {
    conditions: Condition[];
    sendType?: string;
  };
  enabled?: boolean;
}

interface MessageRuleFormProps {
  show: boolean;
  handleSubmit: (data: FormData) => void;
  toggleModal: (toggle: boolean) => void;
  ruleData?: RuleData;
}

const defaulFormData: FormData = {
  ruleName: '',
  enabled: false,
  messageTemplateName: '',
  messageTemplateBody: '',
};

const batchConditions = (conditions: Condition[]) => {
  const allConditions: string[] = [];
  conditions.forEach((condition) => {
    if (typeof condition.value === 'string') {
      allConditions.push(condition.value);
    } else condition.value.forEach((v: string) => allConditions.push(v));
  });
  return allConditions;
};

const conditionsRendered = (conditions: Condition[]) => {
  const conditionValues = batchConditions(conditions);
  return conditionValues.map((condition) => {
    const conditionText = ruleSettingsCopy[condition as keyof typeof ruleSettingsCopy];
    return (
      <div key={condition} className="condition">
        <CheckmarkIcon color="#5E7388" className="ruleIcon" />
        <div className="ruleDetailValue">{conditionText}</div>
      </div>
    );
  });
};
/**
 * Edit Rule and Message Template
 * @param {boolean} show show this modal
 * @param {function} toggleModal trigger change in show
 * @param {function} handleSubmit called when submitting the form
 * */
const MessageRuleForm: FC<MessageRuleFormProps> = ({
  show,
  handleSubmit,
  toggleModal,
  ruleData,
}) => {
  const [formData, setFormData] = useState(defaulFormData);

  const { conditions, sendType } = ruleData?.rule ?? { conditions: [] };

  const sendTypeText = sendType ? ruleSettingsCopy[sendType as keyof typeof ruleSettingsCopy] : '';

  const [canSubmit, setCanSubmit] = useState(false);

  const handleFormFieldChange = (name: string, maxLength: number) => (
    e: { target: { value: string }; },
  ) => {
    const { value } = e.target;
    const newValue = value.slice(0, maxLength);
    setCanSubmit(value.length > 0);
    setFormData({ ...formData, [name]: newValue });
  };

  const toggleRuleEnabled = () => {
    setCanSubmit(true);
    setFormData({ ...formData, enabled: !formData.enabled });
  };

  const handleCancel = () => {
    toggleModal(false);
    setCanSubmit(false);
  };

  useEffect(() => {
    if (!show) {
      setFormData(defaulFormData);
    }
  }, [show]);

  const tokens = useSelector((state: StateType) => state.templates.keys);

  useEffect(() => {
    if (show && ruleData && Object.keys(ruleData).length) {
      // parse template tokens and replace with readable text
      const { annotatedText } = generateReadableBody(tokens, ruleData.messageTemplate);
      setFormData({
        ruleName: ruleData.ruleName,
        enabled: ruleData.enabled,
        messageTemplateName: ruleData.messageTemplate.displayName,
        messageTemplateBody: annotatedText,
      });
    }
  }, [show, ruleData, tokens]);

  // passed into Mentions to render dropdown of available tokens
  // updated when text changes
  const filteredTokens = tokens?.map(({ key }, index) => ({
    id: `${index}`,
    display: key,
  }));

  const templateInput = useRef(null);

  const renderSuggestion = (suggestion: SuggestionDataItem) => (
    <div className="suggestionWrap">
      <div className="user-info">
        <div className="user-name">
          {' '}
          {suggestion?.display}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      show={show}
      title="Edit Rule"
      className="customModalHeader"
      toggleModal={handleCancel}
      size="lg"
    >
      <p className="customHeading">When enabled, the following rule will apply for your organization.</p>
      <div className="scrollableDiv">
        <div className="fullRow">
          <TextInput
            label="Rule Name"
            testId="rule-name-input"
            value={formData.ruleName}
            onChange={handleFormFieldChange('ruleName', MAX_CHAR_TITLE_LENGTH)}
          />
        </div>
        <div className="flexRowEvenly">
          <div>
            <div className="ruleDetailName">Rule Status</div>
            <div className="ruleStatus">
              <SlideToggle
                onToggle={() => toggleRuleEnabled()}
                on={formData.enabled}
                className="ruleIcon"
                testId={`enable-rule-toggle-${formData.enabled ? 'on' : 'off'}`}
              />
              <div className="ruleDetailValue" />
              Enabled
            </div>
          </div>
          <div>
            <div className="ruleDetailName">Send Type</div>
            <div className="ruleStatus">
              {sendTypeText.includes('Send') ? <PaperAirplaneIcon color="#242627" className="ruleIcon" /> : <LightbulbIcon color="#242627" className="ruleIcon" />}
              <div className="ruleDetailValue">{sendTypeText}</div>
            </div>
          </div>
        </div>
        <Divider />
        <p className="ruleDetailName">Active Release Issues</p>
        <div className="flexRowEvenly">
          {conditions.length && conditionsRendered(conditions)}
        </div>
        <Divider />
        <div className="fullRow">
          <TextInput
            label="Message Template Name"
            value={formData?.messageTemplateName}
            onChange={handleFormFieldChange('messageTemplateName', MAX_CHAR_TITLE_LENGTH)}
          />
        </div>
        <p className="ruleDetailName">Message Template - Text Message</p>
        <MentionsInput
          inputRef={templateInput}
          value={formData.messageTemplateBody ?? ''}
          onChange={handleFormFieldChange('messageTemplateBody', MAX_CHAR_INPUT_LENGTH)}
          className="mentionsInput integrationTemplateModal"
          allowSpaceInQuery
        >
          <Mention
            renderSuggestion={renderSuggestion}
            className="noteSuggestion"
            trigger="$"
            data={filteredTokens}
          />
        </MentionsInput>
      </div>

      <ButtonRow>
        <Button
          type="outline"
          onClick={handleCancel}
          testId="template-cancel-button"
        >
          Cancel
        </Button>
        <Button
          type="positive"
          testId="template-submit-button"
          disabled={!canSubmit}
          onClick={() => handleSubmit(formData)}
        >
          Save Rule
        </Button>
      </ButtonRow>

    </Modal>
  );
};

export default MessageRuleForm;
