/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import path from 'path';
import { CHAT_SEND_FILE_CLEAR, UPDATE_MEDIA, UPDATE_VIRUS_SCAN_STATUS } from '../constants/actions';
import { showSnackbar } from './notification';
import { doRequest } from './api';
import { GET_SIGNED_URL_FOR_MEDIA_UPLOAD } from '../constants/endpoints';

export const updateMediaItem = (mediaItem) => ({
  type: UPDATE_MEDIA,
  payload: mediaItem,
});

export function clearFileUploadStatus() {
  return { type: CHAT_SEND_FILE_CLEAR };
}

let uploadCanceler = null;

export const cancelUpload = () => (dispatch) => {
  if (uploadCanceler) {
    uploadCanceler();
    uploadCanceler = null;
  }
  dispatch(clearFileUploadStatus());
};

export const uploadMedia = (
  file,
  validFileTypes = [],
  fileSizeLimitInMegaBytes = 25,
) => async (dispatch) => {
  const filename = file?.name || '';
  const ext = path.extname(filename);
  const cancelToken = (c) => { uploadCanceler = c; };

  const showBadFileSnackBar = () => {
    dispatch(showSnackbar({
      text: "The file you uploaded isn't supported. For more information on the files we support, please visit the ",
      externalLinkText: 'help doc',
      externalLink: 'https://himarley.zendesk.com/hc/en-us/articles/360050427254-Outbound-Media',
      isError: true,
      uncloseableDelay: 0,
      showProgress: true,
    }));
  };

  // Check file type before attempting to create signed url
  if (!validFileTypes.includes(file?.type)) {
    const reason = 'Invalid file type';
    showBadFileSnackBar();
    throw new Error(reason);
  }

  // Get a signed url from the server
  let signedPOSTUrl;
  try {
    signedPOSTUrl = await doRequest({
      endpoint: `${GET_SIGNED_URL_FOR_MEDIA_UPLOAD(ext, file?.type, fileSizeLimitInMegaBytes)}`,
      method: 'GET',
    });
  } catch (e) {
    showBadFileSnackBar();
    throw new Error(e);
  }

  // Format file and signature into a form to POST
  const s3url = signedPOSTUrl?.url;
  const fields = signedPOSTUrl?.fields || {};
  const form = new FormData();
  Object.entries(fields).forEach(([field, value]) => {
    form.append(field, value);
  });
  form.append('Content-Type', file?.type);
  form.append('file', file);

  // Upload file
  try {
    await axios.post(s3url, form, {
      cancelToken: new axios.CancelToken(cancelToken),
    });
  } catch (e) {
    showBadFileSnackBar();
    throw new Error('Failed to upload image');
  }
  return { key: fields.key, url: s3url };
};

export const updateVirusScanStatus = (fileUrl, virusScanStatus) => async (dispatch) => {
  dispatch({
    type: UPDATE_VIRUS_SCAN_STATUS,
    payload: { fileUrl, virusScanStatus },
  });
};
