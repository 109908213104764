import { useQueryClient } from '@tanstack/react-query';
import React, {
  ReactNode, useState, useEffect, useCallback,
} from 'react';
import Linkify from 'react-linkify';
import { connect, useDispatch } from 'react-redux';
import Twemoji from 'react-twemoji';

import {
  updateLastViewedMessage,
} from '@app/actions/job';
import usePermissionCheck from '@app/components/HigherOrderComponents/usePermissionCheck';
import { REDACTED_IMAGE, ROLE_ENDUSER } from '@app/constants/general';
import { CHANNEL } from '@app/constants/message';
import { formatDate } from '@app/helpers/datetime';
import { formatRedactedMessageWithEmojis } from '@app/helpers/emoji';

import SubMessage from './SubMessage';
import TranscribedAudioMessage from './TranscribedAudioMessage';
import ForwardedCallEvent from '../../../../VoiceMessage/forwarded-call-event';
import VoiceMessage from '../../../../VoiceMessage/voice-message';
import RedactionPopup from '../../ChatRedaction/RedactionPopup/RedactionPopup';
import RedactionViewLog from '../../ChatRedaction/RedactionViewLog/redaction-view-log';
import type { RedactionViewedEvent, RedactionData, Message } from '../../types';
import EventMessage from '../EventMessage/EventMessage';
import MediaMessageContainer from '../MediaMessage/MediaMessageContainer';
import MessageFormatter from '../MessageFormatter/MessageFormatter';

import './ChatMessage.less';

const buildRedactionLogs = (
  redactionData: RedactionData | undefined,
  redactionViewedEvents: RedactionViewedEvent[],
) => {
  if (redactionData) {
    const {
      Type,
      createdAt,
      operatorName,
    } = redactionData || {};
    const eventTime = new Date(createdAt).getTime();
    return [{
      Type,
      operatorName,
      eventTime,
    }, ...redactionViewedEvents];
  }
  return redactionViewedEvents;
};

interface ChatMessageProps {
  hasAccess: boolean;
  message: Message;
  todayMessage: boolean;
  isRecipient: boolean;
  orgRedactionEnabled: boolean;
  replaceRedactedTextWithCover: (arg1: Message) => void;
  messageFormatSpecificProps: object | null | undefined;
  hasRedactionViewAccess: boolean;
  firstUnreadMessageRef?: React.RefObject<HTMLDivElement>;
  jobId: string;
  isUnreadMessage?: boolean;
  chatRef: React.RefObject<HTMLDivElement>;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  hasAccess,
  message,
  todayMessage,
  isRecipient,
  orgRedactionEnabled,
  replaceRedactedTextWithCover,
  messageFormatSpecificProps,
  hasRedactionViewAccess,
  firstUnreadMessageRef,
  jobId,
  isUnreadMessage,
  chatRef,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

  const handleScreenClick = useCallback(() => {
    dispatch(updateLastViewedMessage(queryClient, jobId));
  }, [dispatch, queryClient, jobId]);

  useEffect(() => {
    const chatRefCurrent = chatRef?.current;
    if (isUnreadStateRefactorEnabled) {
      if (message?.isFirstUnreadMessage) {
        chatRefCurrent?.addEventListener('click', handleScreenClick, true);
      } else {
        chatRefCurrent?.removeEventListener('click', handleScreenClick, true);
      }
    }

    return () => {
      if (isUnreadStateRefactorEnabled && message?.isFirstUnreadMessage) {
        chatRefCurrent?.removeEventListener('click', handleScreenClick, true);
      }
    };
  }, [chatRef, handleScreenClick, isUnreadStateRefactorEnabled, message?.isFirstUnreadMessage]);

  const todayTimestamp = formatDate(
    message.createdAt,
    'just-time-with-seconds',
  );
  const timestamp = formatDate(message.createdAt, 'date-time-with-seconds');
  const imageUrl = message?.author?.imageUrl;
  const messageBody = message.messageBody || message.body;
  const authorId = message?.author?.id || message?.authorId;
  const { isEvent } = message;
  const isTranslation = message?.additionalInfo?.translatedMessage !== undefined;
  const translatedMessage = message?.additionalInfo?.translatedMessage;
  const englishMessage = isRecipient ? translatedMessage : messageBody;
  const displayMessage = isTranslation && hasAccess ? englishMessage : messageBody;
  const hasRedactionData = message?.redactionData?.length > 0;
  const isRedactionMessage = displayMessage && orgRedactionEnabled && hasRedactionData;
  const messageNeedsAttention = message?.needsAttention || false;
  const redactionViewedEvents = message?.redactionViewedEvents || [];
  const messageStatus = message?.deliveryStatus;
  const messageCreatedAt = message?.createdAt;
  const hasNeedsAction = message?.needsAction?.questionResult === 'hasQuestion' || false;
  const messageIsFromEnduser = message?.author?.role === ROLE_ENDUSER;
  const imageRedactionData = message?.redactionData?.find((r) => r.Type === REDACTED_IMAGE);
  const redactionLogs = buildRedactionLogs(imageRedactionData, redactionViewedEvents);

  let messageToDisplay;
  let subMessage;

  if (!messageIsFromEnduser) {
    messageToDisplay = messageBody;
    subMessage = isTranslation ? translatedMessage : null;
  }

  if (messageIsFromEnduser) {
    if (isTranslation) {
      const messageForRedactedTranslation = {
        ...message,
        messageBody: translatedMessage,
      };
      messageToDisplay = isRedactionMessage
        ? replaceRedactedTextWithCover(messageForRedactedTranslation)
        : translatedMessage;
      // chat-server sets messageBody to redacted body if needed when returning chats
      subMessage = messageBody;
    } else {
      messageToDisplay = isRedactionMessage
        ? replaceRedactedTextWithCover(message)
        : messageBody;
    }
  }

  const isFile = !!message?.fileUrl;
  const isTranscriptionMessage = message?.additionalInfo?.eventType === 'voice'
    && message?.additionalInfo?.recordingUrl;

  const { channel } = message;
  const isVoiceMessage = channel === CHANNEL.VOICE;

  const renderVoiceMessage = isVoiceMessage && !isEvent;
  const renderVoiceEvent = isVoiceMessage && isEvent;
  const renderEvent = !isVoiceMessage && isEvent;
  const renderMediaMessage = !isVoiceMessage && isFile;
  const renderTextMessage = !isVoiceMessage && !isFile;
  const renderMediaUploadFailed = !isVoiceMessage && !isFile && message?.isFile;

  let MessageContent = null;

  if (renderVoiceMessage) {
    MessageContent = (
      <VoiceMessage
        voiceRecordingType={message.mediaInfo?.voiceRecordingType}
        fileUrl={message.fileUrl}
        author={message.authorName}
        createdAt={message.createdAt}
        messageBody={message.messageBody}
        messageId={message.id}
      />
    );
  } else if (renderVoiceEvent) {
    MessageContent = (
      <ForwardedCallEvent
        messageBody={message.messageBody}
      />
    );
  } else if (renderMediaUploadFailed) {
    MessageContent = (
      <span>Media upload failed. Please request customer try again.</span>
    ); 
  } else if (renderTextMessage) {
    MessageContent = (
      <span
        data-test="display-message"
        data-testid={`display-message-${authorId || 'ai'}`}
      >
        {isTranscriptionMessage ? (
          <TranscribedAudioMessage
            recordingUrl={message?.additionalInfo?.recordingUrl || ''}
            messageBody={message?.messageBody || ''}
          />
        ) : (
          <Linkify
        // eslint-disable-next-line react/no-unstable-nested-components
            componentDecorator={(decoratedHref, decoratedText, key) => (
              // this rule wants to include noreferrer with target="_blank"
              // but I'm unsure if we need to keep referrer
              // eslint-disable-next-line react/jsx-no-target-blank
              <a target="_blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {!isRedactionMessage
          && formatRedactedMessageWithEmojis(messageToDisplay)
            .split('\n')
            .map((line: string | ReactNode, index: number) => {
              // eslint-disable-next-line no-param-reassign
              if (line === '') line = <br />;

              return (
                <Twemoji
                  options={{ className: 'emoji' }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${message.id}+${index}`}
                >
                  {line}
                </Twemoji>
              );
            })}
            {isRedactionMessage && (
            <Twemoji
              options={{ className: 'emoji' }}
              key={`${message.id}`}
            >
              {formatRedactedMessageWithEmojis(messageToDisplay)}
              {(redactionLogs?.length > 0 || message?.redactionData?.length > 0) && (
              <RedactionViewLog
                redactionViewedEvents={redactionLogs}
                redactionData={message?.redactionData}
              />
              )}
            </Twemoji>
            )}
            {isTranslation && hasAccess && (
            <SubMessage isOriginal={isRecipient} message={subMessage || ''} />
            )}
          </Linkify>
        )}
      </span>
    );
  } else if (renderMediaMessage) {
    MessageContent = (
      <>
        <MediaMessageContainer
          fileUrl={message.fileUrl}
        />
        {redactionLogs?.length > 0 && (
        <RedactionViewLog redactionViewedEvents={redactionLogs} isImage />
        )}
      </>
    );
  }
  const [showTextRedactionPopup, setShowTextRedactionPopup] = useState(false);
  const toggleTextRedactionPopup = () => setShowTextRedactionPopup(!showTextRedactionPopup);
  const { Type: redactionType } = message?.redactionData?.[0] ?? {};

  return renderEvent ? (
    <EventMessage text={messageBody} message={message} />
  ) : (
    <>
      <RedactionPopup
        showPopup={showTextRedactionPopup}
        togglePopup={toggleTextRedactionPopup}
        hasRedactionViewAccess={hasRedactionViewAccess}
        messageFormatSpecificProps={messageFormatSpecificProps}
        redactionType={redactionType}
      />
      {isUnreadStateRefactorEnabled && message.isFirstUnreadMessage ? (
        <div ref={firstUnreadMessageRef} className="unread-messages-separator" data-testid="unread-messages-separator">
          <div className="text">NEW</div>
          <div className="line" />
        </div>
      ) : null}
      <MessageFormatter
        hasIconBar={!isFile}
        timestamp={todayMessage ? todayTimestamp : timestamp}
        authorName={message.authorName}
        relationship={message.relationship}
        authorNameMessage={message.authorNameMessage}
        authorId={authorId}
        isAI={message.isAI || false}
        imageUrl={imageUrl}
        id={message.id}
        messageId={`message-id-${message.id}`}
        isRecipient={isRecipient}
        displayMessage={displayMessage}
        messageNeedsAttention={messageNeedsAttention}
        messageStatus={messageStatus}
        messageCreatedAt={messageCreatedAt}
        hasNeedsAction={hasNeedsAction}
        eventType={message?.additionalInfo?.eventType}
        isRedactionMessage={hasRedactionViewAccess && isRedactionMessage}
        toggleTextRedactionPopup={toggleTextRedactionPopup}
        isUnreadMessage={isUnreadMessage}
        isInbound={message.isInbound}
      >
        {MessageContent}
      </MessageFormatter>
    </>
  );
};

export default connect(
  () => ({ neededPermissions: ['TRANSLATE_ACCESS'] }),
  null,
  // eslint-disable-next-line react-hooks/rules-of-hooks
)(usePermissionCheck(ChatMessage));
