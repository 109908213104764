import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import EmailConfig from './EmailConfig/EmailConfig';
import {
  GENERAL_INFO,
  CUSTOMER_FEEDBACK,
  CHANGE_PASSWORD,
  EMAIL_CONFIGUREATION,
  AUTO_REPLIES,
  NOTIFICATIONS,
} from '../../constants/configState';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import AutoReplies from './AutoReplies/AutoReplies';
import { setConfigItem } from '../../actions/ui';
import ChangePassword from './ChangePassword/ChangePassword';
import CustomerFeedback from './CustomerFeedback/CustomerFeedback';
import UserNotificationsSettings from './UserNotifications/UserNotificationsSettings';
import {
  getUserProfile,
  getListOfTitles,
  getAutoReplies,
} from '../../actions/profile';
import './ProfileConfig.less';
import { showSnackbar, closeSnackbar } from '../../actions/notification';

const menuItems = {};
menuItems[GENERAL_INFO] = {
  id: GENERAL_INFO,
  title: 'Profile Information',
  view: GeneralInfo,
};
menuItems[NOTIFICATIONS] = {
  id: NOTIFICATIONS,
  title: 'Notifications',
  view: UserNotificationsSettings,
};
menuItems[AUTO_REPLIES] = {
  id: AUTO_REPLIES,
  title: 'Auto Replies',
  view: AutoReplies,
};
menuItems[CUSTOMER_FEEDBACK] = {
  id: CUSTOMER_FEEDBACK,
  title: 'Customer Feedback',
  view: CustomerFeedback,
};
menuItems[CHANGE_PASSWORD] = {
  id: CHANGE_PASSWORD,
  title: 'Change Password',
  view: ChangePassword,
};

menuItems[EMAIL_CONFIGUREATION] = {
  id: EMAIL_CONFIGUREATION,
  title: 'E-mail Configuration',
  // Email config has no default export, this will probably be undefined
  view: EmailConfig,
  newView: true,
};

const ProfileConfig = ({
  configId,
  authUserId,
  profileUserId,
  snackBar,
  ...props
}) => {
  const snackBarText = 'You are editing another user,';

  const RightView = menuItems[configId].view;
  const {
    showSnackbar,
    getUserProfile,
    closeSnackbar,
    setConfigItem,
    identityProvider,
    getListOfTitles,
    getAutoReplies,
  } = props;
  const menus = [
    { id: 'profileInfo', title: 'Profile Information' },
    { id: GENERAL_INFO, title: 'General Info', item: menuItems[GENERAL_INFO] },
    { id: 'feedback', title: 'Feedback' },
    {
      id: CUSTOMER_FEEDBACK,
      title: 'Customer Feedback',
      item: menuItems[CUSTOMER_FEEDBACK],
    },
    { id: 'setings', title: 'Settings' },
    { id: NOTIFICATIONS, title: 'Notifications', item: menuItems[NOTIFICATIONS] },
    { id: AUTO_REPLIES, title: 'Auto Replies', item: menuItems[AUTO_REPLIES] },
    { id: 'security', title: 'Security' },
    {
      id: CHANGE_PASSWORD,
      title: 'Change Password',
      item: menuItems[CHANGE_PASSWORD],
    },
  ];
  const menusFiltered = useMemo(
    () => (identityProvider === 'FEDERATED'
      ? menus.filter(
        (menu) => menu.id !== 'security' && menu.id !== CHANGE_PASSWORD,
      )
      : menus),
    [identityProvider],
  );

  const onSnackbarLinkClick = useCallback(() => {
    getUserProfile(authUserId);
    closeSnackbar();
  }, [getUserProfile, authUserId, closeSnackbar]);

  useEffect(
    () => () => {
      closeSnackbar();
      getAutoReplies(authUserId);
    },
    [],
    [],
  );

  useEffect(() => {
    if (
      authUserId === profileUserId
      && snackBar
      && (snackBar?.text !== 'Away messaging enabled' && snackBar?.text !== 'Settings updated successfully')
      && !snackBar?.isError
    ) {
      closeSnackbar();
    }
  }, [
    authUserId,
    profileUserId,
    closeSnackbar,
    snackBar?.text,
    getAutoReplies,
    snackBar,
  ]);

  useEffect(() => {
    if (profileUserId && authUserId !== profileUserId) {
      showSnackbar({
        text: snackBarText,
        isError: false,
        linkText: 'Edit My Profile',
        persist: true,
        onToggle: () => onSnackbarLinkClick(),
      });
    }
  }, [
    authUserId,
    getUserProfile,
    onSnackbarLinkClick,
    profileUserId,
    showSnackbar,
  ]);

  useEffect(() => {
    getListOfTitles();
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/profile/notifications') {
      setConfigItem('NOTIFICATIONS');
    }
  }, [setConfigItem]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="config-page">
      <div className="left">
        <h5>Menu</h5>
        {menusFiltered.map((menu) => (
          <div className="menu-title" key={menu.id}>
            {menu.item
              ? (
                <button
                  data-testId={`menu-item-${menu.title.toLowerCase()}`}
                  className={`menu-item ${configId === menu.id && 'active'}`}
                  onClick={() => setConfigItem(menu.item.id)}
                  type="button"
                >
                  {menu.title}
                </button>
              )
              : (
                <div className="menu-label">{menu.title}</div>
              )}
          </div>
        ))}
      </div>
      <div className="right">
        <div className="right-inner">
          <RightView />
        </div>
      </div>
    </div>
  );
};

ProfileConfig.propTypes = {
  setConfigItem: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  getListOfTitles: PropTypes.func,
  authUserId: PropTypes.string,
  profileUserId: PropTypes.string,
  identityProvider: PropTypes.string.isRequired,
  getAutoReplies: PropTypes.func.isRequired,
  snackBar: PropTypes.shape({
    text: PropTypes.string,
    isError: PropTypes.bool,
  }),
};
ProfileConfig.defaultProps = {

  getListOfTitles: () => {},
  authUserId: '',
  profileUserId: '',
  snackBar: {},
};

const mapDispatchToProps = {
  setConfigItem,
  getUserProfile,
  showSnackbar,
  closeSnackbar,
  getListOfTitles,
  getAutoReplies,
};

const mapStateToProps = (state) => ({
  configId: _.get(state, 'ui.configId'),
  authUserId: _.get(state, 'auth.user._id'),
  profileUserId: _.get(state, 'profile._id', ''),
  snackBar: _.get(state, 'notification.snackBar'),
  identityProvider: _.get(state, 'auth.user.organization.identityProvider', 'MARLEY'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileConfig);
