import { Tab, Tabs } from '@himarley/unity';
import isEmpty from 'lodash/isEmpty';
import React, {
  useMemo, useEffect, useState, SetStateAction, Dispatch,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import LoBIcon from '@app/components/Settings/CompanyHierarchy/LoBIcon';
import OptInMessages from '@app/components/Settings/OptInMessages/OptInMessages';
import WelcomeFlowSettings from '@app/components/Settings/WelcomeFlowSettings/welcome-flow-settings';
import { LINE_OF_BUSINESS_MESSAGE_TEMPLATES } from '@app/constants/permissions';
import { capitalizeEachWord } from '@app/helpers/format';
import type { StateType, WelcomeFlow } from '@app/types/reducer-state';
import Caret from '@images/icons/large-caret.svg';

import { LOB_TAB_NAMES } from './helpers';
import styles from './LinesOfBusiness.module.less';
import MessageTemplates from '../MessageTemplates/MessageTemplates';

interface LobOptInMessageProps {
  lob: {
    _id: string;
    type: string;
    displayName: string;
    subType: string;
    branding: string;
  };
  setSelectedLob: Dispatch<SetStateAction<string | null>>
}

const LoBOptInMessage: React.FC<LobOptInMessageProps> = ({ lob, setSelectedLob }) => {
  const dispatch = useDispatch();
  const lobWelcomeFlow = useSelector(
    (state: StateType) => state?.organizations?.linesOfBusiness?.find((l) => l?._id === lob?._id)
      ?.welcomeFlow || {},
  );
  const orgWelcomeFlow = useSelector(
    (state: StateType) => state?.organizations?.welcomeFlow || {},
  );
  const [selectedTab, setSelectedTab] = useState(LOB_TAB_NAMES.optInMessages);
  const history = useHistory();

  // change tab based on url param
  useEffect(() => {
    if (history?.location?.pathname) {
      const urlPaths = history.location.pathname.split('/');
      const lastParam = urlPaths[urlPaths.length - 1];
      if (lastParam === 'linesOfBusiness') {
        // nav back
        history.push('/usermgmt/linesOfBusiness');
        setSelectedLob(null);
      } else if (LOB_TAB_NAMES[lastParam]) {
        setSelectedTab(lastParam);
      }
    }
  }, [history?.location?.pathname, setSelectedLob, setSelectedTab, history]);

  const welcomeFlow = useMemo(() => {
    if (isEmpty(lobWelcomeFlow)) {
      const copiedFlow: { [key: string]: WelcomeFlow } = {};
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in orgWelcomeFlow) {
        copiedFlow[key] = {
          template: orgWelcomeFlow[key].template,
          templateKey: orgWelcomeFlow[key].templateKey,
        };
      }
      return copiedFlow;
    }
    return lobWelcomeFlow;
  }, [orgWelcomeFlow, lobWelcomeFlow]);

  const isOrgMessaging = isEmpty(lobWelcomeFlow);

  const { hasAccess: canViewMessageTemplates } = usePermissionVerify({
    neededPermissions: [LINE_OF_BUSINESS_MESSAGE_TEMPLATES],
  });

  const handleTabSelect = (tab: string) => {
    if (tab && lob?._id) {
      history?.push(`/usermgmt/linesOfBusiness/${lob?._id}/${tab}`);
    }
  };

  const lineOfBusinessName = `${capitalizeEachWord(lob?.type)} ${capitalizeEachWord(
    lob?.displayName,
  )}`;

  const isWelcomeFlowRefactorEnabled = process.env.WELCOME_FLOW_REFACTOR_ENABLED;

  const previewBanner = (
    <div
      className={
    isOrgMessaging ? styles.orgMessaging : styles.customMessaging
  }
    >
      {isOrgMessaging ? (
        'Default Org. Setting messaging'
      ) : (
        <span data-testid="customBannerText">
          Custom
          {' '}
          <span className={styles.capitalize}>{lob?.subType}</span>
          {' '}
          messaging
        </span>
      )}
    </div>
  );

  const instructionText = `Configure text messages automatically sent to a customer when a new claim is opened for your ${lineOfBusinessName} line of business.`;

  return (
    <section data-testid="lobPage" className={styles.lobPage}>
      <header className={styles.optInHeader}>
        <div className={styles.nameType}>
          <div className={styles.lobIcon}>
            <LoBIcon name={lob?.subType} />
          </div>
          <div>
            <h3>
              {lob?.type}
              {' '}
              {lob?.displayName}
            </h3>
            <span>{lob?.branding}</span>
          </div>
        </div>
        <div>
          <button className={styles.back} onClick={() => setSelectedLob(null)} type="button">
            <span>
              <Caret />
              {' '}
              Back to Lines
            </span>
          </button>
        </div>
      </header>
      <Tabs
        defaultActiveKey={selectedTab}
        showTabBar={canViewMessageTemplates}
        onSelect={handleTabSelect}
      >
        {isWelcomeFlowRefactorEnabled ? (
          <Tab eventKey={LOB_TAB_NAMES.welcomeMessages} title="Welcome Messages">
            <WelcomeFlowSettings
              tokenOverrideMap={{ $companyName: lob?.branding }}
              lineOfBusiness={lob}
              instructionText={instructionText}
            />
          </Tab>
        ) : (
          <Tab eventKey={LOB_TAB_NAMES.optInMessages} title="Opt-In Messages">
            <OptInMessages
              isLobTab
              tokenOverrideMap={{ $companyName: lob?.branding }}
              lineOfBusinessId={lob?._id}
              dispatch={dispatch}
              className={styles.optInWrap}
              instructionText={instructionText}
              welcomeFlow={welcomeFlow}
              previewBanner={previewBanner}
            />
          </Tab>
        )}
        <Tab eventKey={LOB_TAB_NAMES.templates} title="Message Templates">
          <MessageTemplates
            lineOfBusiness={lob}
          />
        </Tab>
      </Tabs>
    </section>
  );
};

export default LoBOptInMessage;
