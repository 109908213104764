import React from 'react';
import { Divider } from '@himarley/unity';
import PropTypes from 'prop-types';
import IntegrationRule from './IntegrationRule';
import styles from './IntegrationSettingsContainer.module.less';

const IntegrationRulesList = ({ ruleSettings, toggleTemplateModal, toggleRuleModal }) => {
  const ruleIds = Object.keys(ruleSettings);
  const result = ruleIds.map((key, index) => {
    const {
      id, enabled, messageTemplate, rule, ruleName,
    } = ruleSettings[key];

    return (
      <div key={id} data-testid={`integration-rules-list-item-${index}`}>
        <IntegrationRule
          ruleName={ruleName || messageTemplate.displayName}
          messageTemplateText={messageTemplate.body}
          messageTemplateTokens={messageTemplate.tokens}
          messageTemplateDisplayName={messageTemplate.displayName}
          toggleTemplateModal={toggleTemplateModal}
          toggleRuleModal={toggleRuleModal}
          isEnabled={enabled}
          templateId={messageTemplate.id}
          ruleId={id}
          rule={rule}
        />
        <div className={styles.customDivider}>{index < ruleIds.length - 1 && <Divider />}</div>
      </div>
    );
  });
  return result;
};

IntegrationRulesList.propTypes = {
  ruleSettings: PropTypes.shape({}).isRequired,
  toggleTemplateModal: PropTypes.func.isRequired,
};

export default IntegrationRulesList;
