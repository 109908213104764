import { useToast } from '@chakra-ui/react';
import { Button } from '@himarley/unity';
import get from 'lodash/get';
import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { FileDrop } from 'react-file-drop';
import { connect, ConnectedProps } from 'react-redux';

import {
  queueMessage,
  uploadFile,
  sendFile,
  setChatSendFileCompleted,
} from '@app/actions/job';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import { FEATURE_FLAG_VIRUS_SCANNING } from '@app/constants/permissions';
import { isMultiPartyEnabled as shouldShowMultiPartyToolbar } from '@app/helpers/featureFlags';
import { StateType } from '@app/types/reducer-state';

import ChatInput from '../ChatInput/ChatInput';
import ChatLog from '../ChatLog/chat-log';
import './ChatModule.less';
import MultiPartyToolbar from '../MultiPartyToolbar/MultiPartyToolbar';

const mapStateToProps = (state: StateType, ownProps: { chatLocation: string }) => ({
  loadingTemplate: get(state.templates, 'loadingTemplate', false),
  loadedTemplateError: get(state.templates, 'loadedTemplateError', false),
  chat: get(state.jobs, ownProps.chatLocation, {}),
  jobLanguagePref: get(state.jobs, 'activeJob.languagePreference'),
  userPermissions: get(state.auth, 'permissions', []),
  uploadFileIsInProgress: state?.jobs?.uploadFileIsInProgress,
});

const mapDispatchToProps = {
  uploadFile,
  queueMessage,
  sendFile,
  setChatSendFileCompleted,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ChatModuleProps = PropsFromRedux & {
  disabled: boolean;
  isInChatBotMode: boolean;
  isLocked: boolean;
  updateEndUser: () => void;
  isUnlockingUser: boolean;
  chatRef: React.RefObject<HTMLDivElement>;
};

const ChatModule: React.FC<ChatModuleProps> = ({
  loadingTemplate,
  loadedTemplateError,
  disabled,
  chat,
  isInChatBotMode,
  queueMessage: queueMessageFn,
  userPermissions,
  isLocked,
  updateEndUser,
  isUnlockingUser,
  chatRef,
  uploadFile: uploadFileProp,
  uploadFileIsInProgress,
  sendFile: sendFileProp = () => {},
  setChatSendFileCompleted: setChatSendFileCompletedProp = () => {},
}) => {
  const [previewFile, setPreviewFile] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileKey, setFileKey] = useState<File>();
  const toast = useToast();
  const toastIdRef = useRef<HTMLDivElement | any>(null);

  const { hasAccess: hasVirusScanningEnabled } = usePermissionVerify({
    neededPermissions: [FEATURE_FLAG_VIRUS_SCANNING],
  });

  const handleFileSelectChange = useCallback(
    (files: FileList) => {
      if (uploadFileIsInProgress) {
        return;
      }
      const [file] = files;
      setSelectedFile(file);
      if (file) {
        toastIdRef.current = toast({
          description: 'Uploading file...',
          status: 'loading',
          duration: null,
          variant: 'left-accent',
        });
        uploadFileProp(file, toast, toastIdRef).then((response) => {
          const { key } = response || {};
          if (key) {
            sendFileProp(key, file, toast, toastIdRef);
            setFileKey(key);
            if (file?.type?.indexOf('image') > -1 && URL.createObjectURL(file)) {
              setPreviewFile(URL.createObjectURL(file));
            }
          }
        });
      }
    },
    [uploadFileIsInProgress, uploadFileProp, toast, sendFileProp],
  );

  const handleVirusScanQueuing = useCallback(() => {
    if (fileKey && selectedFile && toast) {
      sendFileProp(fileKey, selectedFile, toast, toastIdRef);
    }
  }, [fileKey, sendFileProp, selectedFile, toast]);

  useEffect(
    () => () => {
      if (toastIdRef?.current) {
        toast.close(toastIdRef.current);
      }
      setChatSendFileCompletedProp();
    },
    [setChatSendFileCompletedProp, toast],
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (
      uploadFileIsInProgress
      && fileKey
      && selectedFile
      && hasVirusScanningEnabled
    ) {
      intervalId = setInterval(async () => {
        handleVirusScanQueuing();
      }, 5000); // retry after 5 seconds
    } else if (intervalId) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    hasVirusScanningEnabled,
    fileKey,
    handleFileSelectChange,
    handleVirusScanQueuing,
    selectedFile,
    uploadFileIsInProgress,
  ]);

  const showMultiPartyToolbar = shouldShowMultiPartyToolbar(userPermissions);

  return (
    <div className="chat-module">
      <FileDrop onDrop={(files) => handleFileSelectChange(files as FileList)}>
        <div className="file-drop-target-inner">Upload to Chat</div>
      </FileDrop>
      <ChatLog
        chat={chat}
        isInChatBotMode={isInChatBotMode}
        chatRef={chatRef}
      />
      {(loadingTemplate || loadedTemplateError) && (
        <div className="typing-notification-block">
          <div className="typing-notification">
            {loadingTemplate ? '...loading Template' : 'template load error'}
          </div>
        </div>
      )}
      {isLocked ? (
        <div className="lockedChatInputWrap">
          <p>
            This customer is locked out of texting into this case due to
            exceeding the maximum number of attempted messages without first
            opting-in.
          </p>
          <Button
            onClick={updateEndUser}
            type="outline"
            disabled={isUnlockingUser}
          >
            {isUnlockingUser ? 'Unlocking...' : 'Unlock Customer'}
          </Button>
        </div>
      ) : (
        <>
          {showMultiPartyToolbar && (
            <MultiPartyToolbar enableAddParticipants={!disabled} />
          )}
          <ChatInput
            handleFileSelectChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleFileSelectChange(e?.target?.files as FileList);
              e.target.value = '';
            }}
            previewFile={previewFile}
            chat={chat}
            queueMessage={queueMessageFn}
            disabled={disabled}
            loadedTemplateError={loadedTemplateError}
          />
        </>
      )}
    </div>
  );
};

export default connector(ChatModule);
